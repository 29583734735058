import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonTitle,
  IonContent
} from "@ionic/react";
import "./News.css";

const News: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Wikipedia News</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <iframe
          title="Wikipedia Main Page"
          style={{
            overflow: "hidden",
            height: "100vh",
            width: "100vw"
          }}
          src="https://en.wikipedia.org/wiki/Main_Page"
        />
      </IonContent>
    </IonPage>
  );
};

export default News;
