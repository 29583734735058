import {
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput
} from "@ionic/react";
import React, { useState, useEffect, useCallback } from "react";
import "./Gifs.css";

import { GIPHY_API_KEY } from "../config";

const Tab1: React.FC = () => {
  const [loadedGifUrls, setLoadedGifUrls] = useState();
  const [activeGifUrl, setActiveGifUrl] = useState();
  const [queryTerm, setQueryTerm] = useState();

  const fetchRandomGif = useCallback(async () => {
    const response = await fetch(
      `https://api.giphy.com/v1/gifs/random?tag=${queryTerm}&api_key=${GIPHY_API_KEY}`
    );
    const { data } = await response.json();
    const { embed_url } = data;
    return embed_url;
  }, [queryTerm]);

  useEffect(() => {
    async function setFirstAndSecondGifUrls() {
      const firstGifUrl = await fetchRandomGif();
      const secondGifUrl = await fetchRandomGif();
      setActiveGifUrl([firstGifUrl]);
      setLoadedGifUrls([secondGifUrl]);
    }
    setFirstAndSecondGifUrls();
  }, [fetchRandomGif]);

  useEffect(() => {
    const fetchingGifsInterval = setInterval(async () => {
      if (!window.location.href.endsWith("gifs")) {
        return;
      }
      console.log("ACTIVE GIF URLS", loadedGifUrls);
      setActiveGifUrl(loadedGifUrls.shift());
      if (loadedGifUrls.length === 0 || loadedGifUrls.length === 1) {
        const fetchThreeRandomGifs = new Array(3)
          .fill(undefined)
          .map(() => fetchRandomGif());
        const fiveRandomGifs = await Promise.all(fetchThreeRandomGifs);
        setLoadedGifUrls([...loadedGifUrls, ...fiveRandomGifs]);
      }
    }, 5000);

    return () => clearInterval(fetchingGifsInterval);
  }, [fetchRandomGif, loadedGifUrls]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle slot="start">Random Gifs</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="content">
        <IonCard id="input-card">
          <IonInput
            debounce={400}
            onIonChange={event =>
              setQueryTerm((event.target as HTMLInputElement).value)
            }
            placeholder="Enter a search term"
          ></IonInput>
        </IonCard>
        <IonCard id="active-gif">
          {loadedGifUrls && (
            <iframe
              title="gif-iframe"
              style={{
                border: "none",
                overflow: "hidden",
                height: "100vh",
                width: "100vw"
              }}
              src={activeGifUrl}
            />
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
